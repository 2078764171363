@import "theme/style-screens/quote.css";
@import "theme/style-screens/questionnaire.css";
@import "theme/style-screens/input.css";

:root {
  --prm-color: #0381ff;
  --prm-gray: #b1b1b1;
  --greenia-color: #00a651;
  --colorPrimaryNormal: #00a651;
  --colorPrimaryDark: #00a651;
  --colorPrimaryGlare: #00a651;
  --colorPrimaryHalf: #00a651;
  --colorPrimaryQuarter: #bfecee;
  --colorPrimaryEighth: #dff5f7;
  --colorPrimaryPale: #f3f5f7;
  --colorPrimarySeparator: #f3f5f7;
  --colorPrimaryOutline: #dff5f7;
  --colorButtonNormal: #00a651;
  --colorButtonHover: #00a651;
  --colorLinkNormal: #00a651;
  --colorLinkHover: #00a651;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a,
a:hover {
  color: #00a551;
  font-weight: 600;
  text-decoration: none;
}

.bg-img {
  background-image: url(../assets/images/background-login.webp);
  background-size: cover;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.content-loading {
  align-items: center;
  justify-content: center;
  min-height: 600px;
  z-index: 9000;
}

/* ONLY FOR NOW */
.info-card-rc {
  display: block;
  width: 300px;
}

.info-card-rc p {
  margin-bottom: 0;
  border-bottom: 1px solid #dedede;
}

.pdate {}

.pdate span {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.pdate svg {
  width: 20px;
  height: 20px;
}

.quote-card {
  border-top: 1px solid #00a551;
  background-color: #fbfbf9;
  padding: 15px;
}

.info-buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.info-buttons span {
  padding: 5px;
  background-color: #8bd4b3;
  border-radius: 4px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-buttons-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.info-btn {
  padding: 5px;
  background-color: #8bd4b3;
  border-radius: 4px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 12px;
  cursor: "pointer";
}

.info-buttons span svg {
  width: 15px;
  height: 15px;
  fill: #3c5146;
}

.brand-iamge {
  padding: 20px 0;
  display: block;
}

.brand-iamge img {
  width: 120px;
  margin: auto;
  display: block;
  height: 120px;
  object-fit: contain;
  background-blend-mode: color-burn;
  mix-blend-mode: multiply;
}

.package-brand-iamge {
  display: block;
  padding: 10px 0;
}

.package-brand-iamge img {
  width: 100px;
  margin: auto;
  display: block;
  height: 100px;
  object-fit: contain;
  background-blend-mode: color-burn;
  mix-blend-mode: multiply;
}

.qinfo {
  display: flex;
  font-size: 14px;
}

.qinfo span:first-child {
  display: block;
  width: 40%;
}

.qinfo span:last-child {
  display: block;
  width: 60%;
}

.q-price {
  font-size: 12px;
  line-height: 1;
  display: block;
  margin: auto;
  text-align: center;
}

.a-price {
  font-size: 28px;
  color: red;
  font-weight: 700;
}

.price {
  font-size: 28px;
  color: black;
  font-weight: 700;
}

.action-buttons {
  padding: 15px 0;
}

.action-buttons button.btn {
  display: block;
  width: 100%;
  margin-top: 10px;
}

.action-buttons button.btn.btn-success {
  background-color: #00a551;
}

.x-modal {
  padding: 10px;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.x-modal-cover {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  opacity: 0.5;
  cursor: pointer;
  z-index: 1001;
  background: #525252;
}

.x-modal-content {
  background-color: #ffffff;
  padding: 10px;
  position: relative;
  z-index: 1001;
  border-radius: 10px;
  max-width: 320px;
  text-align: center;
}

.x-modal-body {
  padding: 10px;
}

.x-modal-close {
  position: absolute;
  right: 0;
  width: 36px;
  height: 36px;
  top: -12px;
  background: #fff;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
}

.x-modal-close svg {
  width: 100%;
}

/** Utils */
.title {
  display: block;
  text-align: center;
  padding: 15px;
  background: #00a551;
  color: #fff;
  margin-bottom: 10px;
}

.screen-title {
  padding: 20px;
}

.custom-row {
  display: flex;
  padding: 10px 20px;
  gap: 20px;
}

@media (max-width: 1300px) {
  .custom-row {
    flex-wrap: wrap;
  }
}

.custom-card {
  background-color: white;
  box-shadow: 0px 0px 10px rgb(210, 210, 210);
  flex: 1;
  border-radius: 10px;
}

.custom-card-header {
  padding: 10px 25px;
  padding-top: 15px;
  background-color: rgb(249, 249, 249);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  flex-wrap: nowrap;
}

.custom-card-body {
  padding: 10px 25px;
  padding-bottom: 30px;
  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-wrap: nowrap;
  max-height: 350px;
  overflow-y: auto;
}

.custom-card-body--body-only {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.custom-card-section {
  flex: 1;
  padding: 5px;
}

.h--flex-column {
  flex-direction: column;
}

.custom-card-body--no-height-limit {
  max-height: unset;
}

.custom-table {
  position: relative;
  width: 100%;
  overflow: auto;
  min-height: 100px;
}

.custom-table>thead {
  font-size: 14px;
  color: grey;
  border-bottom: 2px solid rgb(227, 235, 239);
}

.margin-right-24 {
  margin-right: 24px;
}

.custom-table-row {
  font-size: 14px;
  color: rgb(90, 90, 90);
  border-bottom: 1px solid rgb(220, 220, 220);
  background-color: white;
  transition: 0.3s;
}

.custom-table-row.custom-table-total {
  background-color: rgb(250, 250, 250);
}

.custom-table-row:hover {
  background-color: rgb(240, 240, 240);
}

.custom-table-row>td {
  padding: 10px 5px;
}

.custom-table-row>th {
  width: 160px;
  padding: 10px 10px;
}

.custom-table-compact {
  position: relative;
  width: 100%;
  overflow: auto;
}

.custom-table-compact>thead {
  font-size: 14px;
  color: grey;
  border-bottom: 2px solid rgb(227, 235, 239);
}

.custom-table-row-compact {
  font-size: 14px;
  color: rgb(90, 90, 90);
  border-bottom: 1px solid rgb(220, 220, 220);
  background-color: white;
  transition: 0.3s;
}

.custom-table-row-compact:hover {
  background-color: rgb(240, 240, 240);
}

.custom-table-row-compact>td {
  padding: 5px 5px;
}

.custom-table-empty {
  position: absolute;
  top: 30px;
  left: 0;
  padding: 10px 20px;
  width: 100%;
  text-align: center;
  color: grey;
}

.alert-general-error {
  margin: 20px;
  padding: 5px 10px;
  color: white;
  font-weight: bold;
  text-align: center;
  background-color: lightcoral;
  border-radius: 10px;
}

.general-form-error {
  margin: 20px 0;
  padding: 5px 10px;
  color: white;
  font-weight: bold;
  text-align: center;
  background-color: lightcoral;
  border-radius: 10px;
}

.general-input-error {
  color: #dc3545;
  font-size: 14px;
}

.general-modal-content {
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  background-color: #ffffff;
  padding: 10px;
  position: relative;
  z-index: 1001;
  border-radius: 10px;
}

.general-modal-close {
  position: absolute;
  right: 0;
  width: 36px;
  height: 36px;
  top: 0px;
  background: #fff;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
}

.general-modal-close svg {
  width: 100%;
}

.package-card {
  padding: 10px;
  width: 100%;
  border: 2px solid;
  border-radius: 0.313rem;
}

.package-card-tlb {
  border-color: brown;
}

.package-card-tls {
  border-color: silver;
}

.package-card-tlg {
  border-color: gold;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--greenia-color);
  --bs-btn-border-color: var(--greenia-color);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--greenia-color);
  --bs-btn-disabled-border-color: var(--greenia-color);
}

.btn-outline-success {
  --bs-btn-color: var(--greenia-color);
  --bs-btn-border-color: var(--greenia-color);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--greenia-color);
  --bs-btn-hover-border-color: var(--greenia-color);
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--greenia-color);
  --bs-btn-active-border-color: var(--greenia-color);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--greenia-color);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--greenia-color);
  --bs-gradient: none;
}

.pac-container {
  z-index: 3000 !important;
}

.form-container {
  margin: auto;
  width: 100%;
  max-width: 600px;
  padding: 10px;
}

.apexcharts-toolbar {
  z-index: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.index-vehicle-3 {
  width: 75%;
}

@media screen and (max-width: 600px) {
  .index-vehicle-3 {
    width: 100%;

  }
}

.overlay-everything {
  z-index: 2000 !important;
}

.margin-left-negative-130 {
  margin-left: -130px;
}